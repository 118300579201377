<template>

  <div class="addDing is-full-height is-overflow-y-scroll">
  
    <back-button class="ml-2"/> 
    <ImageUpload :startImageArray="item.pictures" @imageUploadChangedPreviewImages="handleImageEdits"/>
     
    <div class="is-flex is-flex-direction-column is-align-items-center is-full-width">

      <div class="is-full-width broad-input m-1">
        <label for="nameinput" class="is-float-left has-text-weight-bold">Name</label>
        <Autocomplete
                  id="nameinput"
                  ref="itemNameInput"
                  debounce=1800
                  max=5
                  use-html-for-results=true
                  input-minlength="4"
                  input-class="input is-rounded"
                  results-container-class="autocomplete-result-container has-background-light"
                  results-item-class="green-on-hover vue3-results-item"
                  placeholder="name of item"
                  @input="handleItemNameUpdate"
                  :results="itemNameResults"
                  @onSelect="handleItemNameSelect($event,itemNameInput)"
                  @blur="clearAutocomplete(itemNameInput)"
                  autocomplete="off"
          ></Autocomplete>
      </div>
      
      <AddDingLocation :showGeolocation="$route.query.showGeolocation" :item="item" @locationUpdate="handleLocationUpdate"/>

      <div class="is-full-width broad-input mt-2 mb-2">
        <textarea v-model="item.description" class="textarea" placeholder="description" rows="4"></textarea>
      </div>

      <!--
      <div class="is-full-width broad-input mt-2 mb-2" v-if="$route.query.showGeolocation == undefined">
        <label for="categoryselect" class="is-float-left has-text-weight-bold" style="width:1px">Category</label>  
        <div id="categoryselect" class="select is-full-width">
          <select class="is-full-width" v-model="category">
            <option v-for="key in $store.state.ui.categories" :key="key" :default="key == 'all'" :value="key">{{key.toUpperCase()}}</option>
          </select>
        </div>
      </div>
      -->

      <div class="is-full-width broad-input mb-2">
          <label for="taginput" class="is-float-left has-text-weight-bold">Specifics / Tags</label>
          <Tagsinput 
            v-model="item.tags"
            id="taginput" 
            placeholder="add specific tags"
            @tagInputUpdate="handleUpdateTagInput" 
            @removeTag="removeTag($event)"
            @addTag="handleAddTag"
            :searchResults="tagInputAutocomplete"
            :filterDisplayedTags="tagCanBeShownInTagInput"
            :forbiddirectAdding="forbiddirectAddingENV"
            ></Tagsinput>
      </div>

      <div class="is-flex is-85-pc-width is-justify-content-center">
            <div class="broad-input mb-2 is-third-width m-1"> 
              <label class="is-float-left has-text-weight-bold">Depth / Length (cm)</label>
              <input class="input is-rounded" type="Number" inputmode="numeric" step="1" min="0" v-model="item.length" placeholder="length"/>
            </div>

            <div class="broad-input mb-2 is-third-width m-1">
              <label class="is-float-left has-text-weight-bold">Width (cm)</label>
              <input class="input is-rounded" type="Number" inputmode="numeric" step="1" min="0" v-model="item.width" placeholder="width"/>
            </div>

            <div class="broad-input mb-2 is-third-width m-1">
              <label class="is-float-left has-text-weight-bold">Height (cm)</label>
              <input class="input is-rounded" type="Number" inputmode="numeric" step="1" min="0" v-model="item.depth" placeholder="height"/>
            </div>

      </div>
  
      <!-- <div class="is-full-width broad-input" v-if="shouldBeShownByCategory('sizes',category,$store)">
        <label class="is-float-left has-text-weight-bold">Sizes</label>
        <Tagsinput
          :modelValue="sizetags" 
          placeholder="add sizes"
          @addTag="addTag('size:'+$event)"
          @removeTag="removeTag('size:'+$event)"
          ></Tagsinput>
      </div> -->

      <div class="is-flex is-85-pc-width is-justify-content-center">
        <div class="is-full-width broad-input mb-2 mr-1" v-if="$route.query.showGeolocation == undefined">
          <label class="is-float-left has-text-weight-bold">quantity/count</label>
          <input class="input is-rounded" type="Number" inputmode="numeric" step="1" min="1" v-model="item.count" placeholder="count"/>
        </div>

        <div class="is-full-width broad-input mb-2" v-if="$route.query.showGeolocation == undefined">
          <label class="is-float-left has-text-weight-bold">Weight (kg)</label>
          <input class="input is-rounded" type="Number" inputmode="decimal" step="0.01" min="0" v-model="item.weight" placeholder="add weight"/>
        </div>
      </div>

      <div class="is-flex is-85-pc-width is-justify-content-center">
        <div class="is-full-width broad-input mb-2 mr-1" v-if="$route.query.showGeolocation == undefined">
          <label class="is-float-left has-text-weight-bold">Buying Price (EUR) {{item.price}}</label>
          <input class="input is-rounded" type="Number" inputmode="decimal" step="0.01" min="0" v-model="item.price" placeholder="add price"/>
        </div>
      
        <div class="is-full-width broad-input mb-2" v-if="$route.query.showGeolocation == undefined">
          <label class="is-float-left has-text-weight-bold">Current Value (EUR)</label>
          <input class="input is-rounded" type="Number" inputmode="decimal" step="0.01" min="0" v-model="item.value" placeholder="add value"/>
        </div>
      </div>

      <div class="is-full-width broad-input mb-2" v-if="$route.query.showGeolocation == undefined">
        <label class="is-float-left has-text-weight-bold">COUNTRY OF ORIGIN</label>
        <input class="input is-rounded" type="TEXT" v-model="item.countryoforigin" placeholder="country code (DE/CN/US etc.)"/>
      </div>


      <div class="is-flex is-85-pc-width is-justify-content-center">
        <div class="is-full-width broad-input mb-2 mr-1" v-if="$route.query.showGeolocation == undefined">
          <label class="is-float-left has-text-weight-bold">BILLING REFERENCE</label>
          <input class="input is-rounded" type="TEXT" v-model="item.billingreference" placeholder="unique reference for bookkeeping/abrechnung"/>
        </div>

        <div class="is-full-width broad-input mb-2">
          <label class="is-float-left has-text-weight-bold">INVOICE DATE</label>
          <input class="input is-rounded" type="TEXT" v-model="item.invoicedate" placeholder="date of bill/invoice"/>
        </div>
      </div>

           
      <div class="is-full-width broad-input mb-3">
        <label class="is-float-left has-text-weight-bold">OWNED BY {{item.owned}}</label>
        <Tagsinput 
          :modelValue="item.owned" 
          placeholder="owner(s)"
          @addTag="handleOwnedAdded"
          @removeTag="handleOwnedRemoved"
          inputtype="String"
          ></Tagsinput>
      </div>

      <div class="is-full-width broad-input mb-3">
        <label class="is-float-left has-text-weight-bold">VISIBLE TO</label>
        <Tagsinput 
          :modelValue="item.visible2" 
          placeholder="lent to / borrowed by"
          @addTag="item.visible2.includes($event) ? null : item.visible2.push($event)"
          @removeTag="item.visible2 = item.visible2.filter((o)=>o !== $event)"
          inputtype="String"
          ></Tagsinput>
      </div>
    
    </div>

    <button :class="{'button m-2 mt-3 mb-4 has-background-purple-1 is-rounded has-text-light has-border-black':true,'is-loading':isLoading}" :disabled="isLoading" @click="handleAddItem">
      {{$route.query.showGeolocation ? 'CREATE STORAGE' : 'ADD ITEM' }}
    </button>
    
    <button class="button m-2 mt-3 mb-4 has-background-light is-rounded has-text-dark has-border-black" @click="handleCancel">CANCEL</button>

  </div>
  
</template>

<script>
import ImageUpload from '@/components/ItemForm/ImageUpload.vue'
import Autocomplete from '@/components/Template/Autocomplete.vue'
import AddDingLocation from '@/components/ItemForm/addDingLocation.vue'
import Tagsinput from '@/components/Template/TagInput'
import BackButton from '@/components/Template/BackButton.vue';

import { ref, onMounted, onUnmounted, computed} from "@vue/runtime-core";
import { useStore } from "vuex";
import {useRoute, useRouter} from "vue-router"
import {shouldBeShownByCategory} from '@/helpers.js';

export default {
  name: 'AddDing',
  components: {
    Autocomplete,
    BackButton,
    ImageUpload,
    AddDingLocation,
    Tagsinput,
  },
  setup(){
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const ui = computed(()=> store.state.ui)

    const lastItemAdded = computed(()=>store.state.data.lastItemAdded)

    const forbiddirectAddingENV = ref(process.env.VUE_APP_DIRECT_TAG_ADDING_ALLOWED?.toLowerCase() != "true");

    let itemstartvalue = {
      name: '',
      owned: sessionStorage.addItemOwned ? JSON.parse(sessionStorage.addItemOwned) : [store.state.data.username],
      visible2: [], 
      atLocation: null,
      insideof: route.query.insideof || ui.value.config.rememberLastStorageInAdd && ui.value.lastinsideof || null,
      weight:null,
      count:null,
      tags:[],
      pictures:[],
      value:null,
      price:null,
      width:null,
      depth:null,
      length:null,
      countryoforigin: null,
      billingreference: null,
      invoicedate: null,
    }


    const item = ref(itemstartvalue);

    const category = ref('all');

    const itemNameInput = ref(null);
    const tagInputAutocomplete = ref([]);
    const itemNameResults = ref([]);
    // const newsizetag = ref('');
    // const sizetags = computed(()=>{return item.value.tags.filter((t)=>t.startsWith("size:"))})

    const isLoading = ref(false);

    onMounted(()=>{
      store.dispatch("hideTabBar");
      if (route.query.similarItem){
        if (!lastItemAdded.value) return
        console.log("similarItem flag set. will merge",lastItemAdded.value.pictures);
        item.value = Object.assign(item.value,lastItemAdded.value);
        if (!lastItemAdded.value.name) return
        itemNameInput.value.setText(item.value.name);
      }
    })

    onUnmounted(()=>{
      store.dispatch("showTabBar");
    })


    const handleAddItem = async ()=>{
      console.log("adding item", item.value);
      item.value.owned = item.value.owned.filter((o)=>{return o})
      isLoading.value = true;
      let result = await store.dispatch("addItem",{item:item.value})
      isLoading.value = false;
      if (result && result.status == 200){
        //store.dispatch("setLastItemAdded",{key:"lastItemAdded",value:item.value})
        // clear/reload or show success screen after clearing
        item.value = itemstartvalue;
        router.push(('/success?addedSingle='+result.data.id));
      }
    }

    const handleUpdateTagInput = async (e)=>{
        if (e.length > 3){
          let autocomplete = await store.dispatch("searchTags",{tag:e});
          tagInputAutocomplete.value = autocomplete.map((i)=>i.tag);
        }
        else {
          clearAutocomplete(tagInputAutocomplete)
        }
    };
   
    const addTag = async (tag)=> {
      console.log("addTag",tag);
      if (!item.value.tags.includes(tag)){
        item.value.tags.push(tag);
      }
    }

    const removeTag = async (tag)=> {
      item.value.tags.splice(item.value.tags.indexOf(tag), 1);
    }

    // TODO: refactor into helpers.js 
    /*
    this is a proof of concept function to filter out specific key-value pairs 
    from being displayed.
    e.g.: tagsThatHaveTheirOwnFormField = ["size:","dimension:"]
    */
    const tagsThatHaveTheirOwnFormfield = []
    const tagCanBeShownInTagInput = ({tag})=>{
        if(tagsThatHaveTheirOwnFormfield.some((t)=>tag.startsWith(t))){
          return false
        }
        else{
          return true
        }
    }

    const handleItemNameUpdate = async (e)=>{ 
        console.log(e);
        item.value.name = e;
        if (e.length > 3){
          console.log("will search");
          /*
          TODO: 
            - for item name use full input
            - for tags only use the last word (split by spaces and use last)
          */
          let autocomplete = await store.dispatch("searchItemsAndTags",{text:e})
          console.log("autocomplete response",autocomplete);
          if (autocomplete?.items){
            console.log("name autocomplete items to check",autocomplete.items);
            itemNameResults.value = autocomplete.items.map((i)=>`<span class="has-background-light"><dingsda-name>${i.name}</dingsda-name></span> (autocomplete)`);
          }
          if (autocomplete?.tags){
            itemNameResults.value = itemNameResults.value.concat(
            autocomplete.tags.map((i)=>`<span class="has-background-green-3"><dingsda-tag class='tag is-darkgrey'>${i.tag}</dingsda-tag></span> (add as tag)`)
            )
          }  
        }
         else {
          clearAutocomplete(itemNameInput);
        }
    };

    const handleItemNameSelect = async (event,source)=> {
      if (event.includes("<dingsda-tag>")){
        let tag = event.split("<dingsda-tag>")[1].split("</dingsda-tag>")[0];
        await addTag(tag);
      }
      if (event.includes("<dingsda-name>")){
        let name = event.split("<dingsda-name>")[1].split("</dingsda-name>")[0];
        itemNameInput.value.searchText = name;
      }      
      clearAutocomplete(source);
      //source.autocompleteRef.blur();
    }

    const clearAutocomplete = (source) => {
       source.value = []
    }

    const handleAddTag = ()=>{
      clearAutocomplete(tagInputAutocomplete)
    }

    const handleLocationUpdate = (e)=>{
      console.log("location update event received",e);
      if (!e.insideof && !e.atLocation){
        console.log("location empty. will empty field in item ");
        item.value.insideof = null;
        item.value.atLocation = null;
        return;
      }
      if (e.insideof){
        item.value.insideof = e.insideof;
        store.commit('setUI',{key:'lastinsideof',value:item.value.insideof})
      }
      if (e.atLocation){
        item.value.atLocation = e.atLocation;
      }
    }

    const handleCategoryUpdate = (e)=>{
      category.value = e.target.value;
    }

    const handleImageEdits = async (e)=>{
      let newPreviewImageArray = e;
      console.log("AddDing received new imageArray",newPreviewImageArray);
      item.value.pictures = newPreviewImageArray;
    }

    const handleCancel = ()=>{
      //router.go(0)
      router.go(-1);
      store.dispatch("setPhotosToUpload",{files:null});
      setTimeout(()=>{
        router.go(1);
      },100)
      
    }

    const handleOwnedAdded = ($event)=>{
      item.value.owned.includes($event) ? null : item.value.owned.push($event)
      sessionStorage.addItemOwned = JSON.stringify(item.value.owned);
    }

    const handleOwnedRemoved = ($event)=>{
      item.value.owned = item.value.owned.filter((o)=>o !== $event);
      sessionStorage.addItemOwned = JSON.stringify(item.value.owned);
    }


    return {
        item,
        category,
        itemNameInput,
        itemNameResults,
        handleItemNameSelect,
        handleItemNameUpdate,
        handleAddTag,
        tagInputAutocomplete,
        handleUpdateTagInput,
        tagCanBeShownInTagInput,
        //sizetags,
        //newsizetag,
        clearAutocomplete,
        addTag,
        removeTag,
        handleLocationUpdate,
        handleCategoryUpdate,
        shouldBeShownByCategory,
        handleAddItem,
        handleImageEdits,
        isLoading,
        handleCancel,
        handleOwnedAdded,
        handleOwnedRemoved,
        forbiddirectAddingENV,
    }
  }
}
</script>